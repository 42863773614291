export default {
  素材: "素材",
  素材编辑: "素材编辑",
  基础信息: "基础信息",
  商品名称: "商品名称",
  商品ID: "商品ID",
  商品价格: "商品价格",
  参考视频: "参考视频",
  最多上传6个视频: "最多上传6个视频",
  TikTok视频链接: "TikTok视频链接",
  新增链接: "新增链接",
  卖点参考: "卖点参考",
  最多可填写5个卖点: "最多可填写5个卖点",
  新增卖点: "新增卖点",
  brief参考: "brief参考",
  请上传brief脚本链接: "请上传brief脚本链接",
  brief链接: "brief链接",
  图片素材: "图片素材",
  "最多上传9张图片，每张图片不大于5M": "最多上传9张图片，每张图片不大于5M",
  上传多张图片: "上传多张图片",
  视频素材: "视频素材",
  "最多上传9个视频，每个视频不大于50M": "基础信息最多上传9个视频，每个视频不大于50M",
  上传多个视频: "上传多个视频",
  保存: "保存",
  点击或将文件拖拽到这里上传: "点击或将文件拖拽到这里上传",
  "支持扩展名：.mp4、.mov、.m4v": "支持扩展名：.mp4、.mov、.m4v",
  继续保存: "继续保存",
  "将在APP端展示您上传的资料，你还要继续吗？": "<span style='color: red'>将在APP端展示您上传的资料</span>，你还要继续吗？",
  放弃素材编辑: "放弃素材编辑",
  "当前编辑将全部失效，你还要继续吗？": "当前编辑将全部失效，你还要继续吗？",
  取消: "取消",
  继续: "继续",
  "保存成功！": "保存成功！",
  卖点x: "卖点{index}",
  卖点x简介: "卖点{index}简介",
  上传: "上传",
  'You can only upload JPG/JPEG/PNG/GIF file!': '您只能上传 JPG/JPEG/PNG/GIF 文件！',
  'Image must smaller than 5MB!': '图片必须小于 5MB',
  "You can only upload mp4/mov/m4v file!": "您只能上传 mp4/mov/m4v 文件！",
  "Video must smaller than 50MB!": "视频必须小于 50MB！",
  "Upload failed": "上传失败",
};
