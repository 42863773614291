const operation_icon = "https://file.uchoice.pro/public/img/240722/4.png";
const operation_icon_active = "https://file.uchoice.pro/public/img/240722/2.png";

const operationRoute: IRouteProps[] = [
  {
    path: "/operation",
    name: "运营管理",
    meta: { icon: operation_icon, activeIcon: operation_icon_active },
    routes: [
      {
        path: "/operation/homeBanner",
        name: "banner配置",
        component: "./operation/homeBanner"
      },
      {
        path: "/operation/homeBanner/add",
        name: "添加",
        component: "./operation/homeBanner/add",
        hideInMenu: true
      },
      {
        path: "/operation/homeBanner/edit/:id",
        name: "编辑",
        component: "./operation/homeBanner/add",
        hideInMenu: true
      },
      {
        path: "/operation/homeBanner/detail/:id",
        name: "详情",
        component: "./operation/homeBanner/add",
        hideInMenu: true
      },
      // 首页配置
      // {
      //   path: "/operation/homeRecommend",
      //   name: "首页配置",
      //   component: "./operation/homeRecommend"
      // },
      //动态页列表
      {
        path: "/operation/customPage",
        name: "动态页配置",
        component: "./operation/customPage"
      },
      //新增动态页
      {
        path: "/operation/customPage/add",
        name: "新增动态页",
        component: "./operation/customPage/add",
        hideInMenu: true
      },
      //动态页详情
      {
        path: "/operation/customPage/detail/:id",
        name: "编辑动态页",
        component: "./operation/customPage/add",
        hideInMenu: true
      },

      // 瀑布流配置
      {
        path: "/operation/waterfallFlow",
        name: "瀑布流配置",
        component: "./operation/waterfallFlow"
      },
      // 新增瀑布流
      {
        path: "/operation/waterfallFlow/add",
        name: "添加",
        component: "./operation/waterfallFlow/add",
        hideInMenu: true
      },
      // 编辑瀑布流
      {
        path: "/operation/waterfallFlow/edit/:id",
        name: "添加",
        component: "./operation/waterfallFlow/add",
        hideInMenu: true
      },
      // 瀑布流详情
      {
        path: "/operation/waterfallFlow/detail/:id",
        name: "添加",
        component: "./operation/waterfallFlow/add",
        hideInMenu: true
      },
      {
        path: "/operation/leaderboard",
        name: "排行榜配置",
        component: "./operation/leaderBoard"
      },
      {
        path: "/operation/leaderboard/add",
        name: "排行榜新增",
        component: "./operation/leaderBoard/add",
        hideInMenu: true
      },
      {
        path: "/operation/leaderboard/edit/:id",
        name: "排行榜编辑",
        component: "./operation/leaderBoard/add",
        hideInMenu: true
      },
      {
        path: "/operation/newer/collections",
        name: "新人样品池配置",
        component: "./operation/newer/collections"
      },
      {
        path: "/operation/SampleApplyThreshold",
        name: "uChoice&TT样品申请门槛设置",
        component: "./operation/sampleApplyThreshold",
        hideInMenu: false
      },
      // 投流管理
      {
        path: "/operation/inflowManangement/index",
        name: "投流管理",
        component: "./operation/inflowManangement/index",
        access: "canOporation" //权限定义返回值的某个key
      }
    ]
  }
];

export default operationRoute;
