import http from "./instance";

export interface GetShortLinkParams {
  url: string;
  type?: number;
}

export interface BucketPolicyParams {
  application?: string;
  productId?: string;
}

export const getShortLink = async (data: GetShortLinkParams): Promise<IResponseProps<string>> => {
  return http.post<GetShortLinkParams>("/api-base/link/getLongLink", { ...data, type: 6 });
};

export const bucketPolicy = async (data: BucketPolicyParams): Promise<IResponseProps<string>> => {
  return http.postJSON<BucketPolicyParams>("api-base/upload/bucketPolicy", data,);
};
