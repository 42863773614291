export default {
  素材: "Materials",
  素材编辑: "Material Editing",
  基础信息: "Basic Information",
  商品名称: "Product Name",
  商品ID: "Product ID",
  商品价格: "Product Price",
  参考视频: "TOP Reference Video",
  最多上传6个视频: "Upload up to 6 videos",
  TikTok视频链接: "TikTok Video Link",
  新增链接: "Add Link",
  卖点参考: "Selling Points Reference",
  最多可填写5个卖点: "Add up to 5 selling points",
  新增卖点: "Add new",
  brief参考: "Brief Reference",
  请上传brief脚本链接: "Please upload brief script link",
  brief链接: "Brief Link",
  图片素材: "Image Material",
  "最多上传9张图片，每张图片不大于5M": "Upload up to 9 images (≤5M each)",
  上传多张图片: "Upload multiple images",
  视频素材: "Video Material",
  "最多上传9个视频，每个视频不大于50M": "Upload up to 9 videos (≤50M each)",
  上传多个视频: "Upload multiple videos",
  保存: "Save",
  点击或将文件拖拽到这里上传: "Click or drag files to upload",
  "支持扩展名：.mp4、.mov、.m4v": "Supported formats: .mp4, .mov, .m4v",
  继续保存: "Continue",
  "将在APP端展示您上传的资料，你还要继续吗？":
    '<sapn style="color: red;">Data will show on uChoice pro APP</sapn>, continue?',
  放弃素材编辑: "Cancel material editing",
  "当前编辑将全部失效，你还要继续吗？": "All edits will be lost, continue?",
  取消: "Cancel",
  继续: "Continue",
  "保存成功！": "Save successful!",
  卖点x: "Selling Point {index}",
  卖点x简介: "Selling Point {index} Description",
  上传: "Upload",
  'You can only upload JPG/JPEG/PNG/GIF file!': 'You can only upload JPG/JPEG/PNG/GIF file!',
  'Image must smaller than 5MB!': 'Image must smaller than 5MB!',
  "You can only upload mp4/mov/m4v file!": "You can only upload mp4/mov/m4v file!",
  "Video must smaller than 50MB!": "Video must smaller than 50MB!",
  "Upload failed": "Upload failed",
};
