export default {
  达人表现: "Hiệu suất người sáng tạo của {name}",
  我的达人分析: "Phân tích người sáng tạo của tôi",
  日期区间: "Khoảng thời gian",
  PIC: "PIC",
  "GMV（预估）": "GMV (Ước tính)",
  "TAP佣金收入（预估）": "Thu nhập hoa hồng TAP (Ước tính)",
  "GMV（完成）": "GMV (Hoàn thành)",
  "TAP佣金收入（完成）": "Thu nhập hoa hồng TAP (Hoàn thành)",
  PIC榜单: "Bảng xếp hạng PIC",
  "All PIC达人表现": "Hiệu suất tất cả nhà sáng tạo của PIC",
  PIC达人分析: "Phân tích nhà sáng tạo PIC",
  栏目名称: '栏目名称',
  位置: '位置',
  瓷片区: '瓷片区',
  栏目链接: '栏目链接',
  英文: '英文',
  泰文: '泰文',
  越南语: '越南语',
  修改后APP首页将实时生效: '修改后APP首页将实时生效',
  '你还要继续吗？': '你还要继续吗？',
  '修改配置': '修改配置',
};
