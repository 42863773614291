export default {
  素材: "Tài liệu",
  素材编辑: "Chỉnh sửa tài liệu",
  基础信息: "Thông tin cơ bản",
  商品名称: "Tên sản phẩm",
  商品ID: "Mã sản phẩm:",
  商品价格: "Giá sản phẩm",
  参考视频: "Video tham khảo",
  最多上传6个视频: "Tải lên tối đa 6 video",
  TikTok视频链接: "Liên kết video TikTok",
  新增链接: "Thêm liên kết",
  卖点参考: "Tham khảo điểm bán hàng",
  最多可填写5个卖点: "Tối đa thêm 5 điểm bán hàng",
  新增卖点: "Thêm mới",
  brief参考: "Tóm tắt tham khảo",
  请上传brief脚本链接: "Vui lòng tải lên liên kết tóm tắt kịch bản",
  brief链接: "Liên kết tóm tắt",
  图片素材: "Tài liệu hình ảnh",
  "最多上传9张图片，每张图片不大于5M": "Tải lên tối đa 9 ảnh (mỗi ảnh không quá 5M)",
  上传多张图片: "Tải lên nhiều ảnh",
  视频素材: "Tài liệu video",
  "最多上传9个视频，每个视频不大于50M": "Tải lên tối đa 9 video (mỗi video không quá 50M)",
  上传多个视频: "Tải lên nhiều video",
  保存: "Lưu",
  点击或将文件拖拽到这里上传: "Nhấn hoặc kéo tệp vào đây để tải lên",
  "支持扩展名：.mp4、.mov、.m4v": "Định dạng hỗ trợ: .mp4, .mov, .m4v",
  继续保存: "Tiếp tục lưu",
  "将在APP端展示您上传的资料，你还要继续吗？":
    '<span style="color: red;">Dữ liệu sẽ hiển thị trên ứng dụng</span>, bạn có muốn tiếp tục?',
  放弃素材编辑: "Hủy chỉnh sửa tài liệu",
  "当前编辑将全部失效，你还要继续吗？": "Mọi chỉnh sửa sẽ bị mất, bạn có muốn tiếp tục?",
  取消: "Hủy",
  继续: "Tiếp tục",
  "保存成功！": "Lưu thành công!",
  卖点x: "Điểm bán hàng {index}",
  卖点x简介: "Mô tả điểm bán hàng {index}",
  上传: "Tải lên",
  'You can only upload JPG/JPEG/PNG/GIF file!': 'Bạn chỉ có thể tải lên tệp JPG/JPEG/PNG/GIF!',
  'Image must smaller than 5MB!': 'Hình ảnh phải nhỏ hơn 5MB!',
  "You can only upload mp4/mov/m4v file!": "Bạn chỉ có thể tải lên tệp mp4/mov/m4v!",
  "Video must smaller than 50MB!": "Video phải nhỏ hơn 50MB!",
  "Upload failed": "Tải lên thất bại",
};
