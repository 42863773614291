export default {
  supplierName: "Supplier Name",
  supplierId: "Supplier ID",
  numberOfStores: "Store Quantity",
  storeName: "Store Name",
  addTime: "Adding Time",
  storeNum: "Store ID",
  unprocessedSampleRequest: "Unprocessed Sample Applications",
  shipLink: "Shipping Link",
  deleteLinkTips:
    "After deletion, the link becomes invalid and the supplier information cannot be restored. Confirm to delete? ",
  deleteShopTips:
    "After deletion, the supplier cannot deliver samples to this store. Are you sure you want to delete?",
  move: "Move",
  operationRecords: "Operation Records",
  cooperativeProductQuantity: "Number of cooperation products",
  activityProductQuantity: "Number of products in campaign",
  canAdd: "Add",
  added: "Added",
  belongToApp: "Already bound to other suppliers",
  addSuccess: "Added successfully",
  noticeTips: "Note: Only suppliers you have cooperated with can be added",
  moveId: "Please select the Supplier ID that you want to move to",
  supplier: "Supplier",
  inputShopNameOrNum: "Please enter store name or store ID to search",
  inputSupplierId: "The supplier name does not exist.",
  shelfStatus: "Merchant status",
  immediateProcessing: "Bind Now",
  lastSampleDate: "Last Sample Request Time",
  sample3day: "Pending Approval (last 3d)",
  merchantContactInformation: "Contact Number",
  noBindPer: "x shop(s) are pending to bind with suppliers!",
  willBindNew: "After creation, xxx will bind with this supplier",
  waitBindShop: "Shop Pending Binding",
  pendingMerchantReviewNum: "Pending Approval",
  newSupplyChain: "New Supplier",
  bindSupplyChain: "Bind Supplier",
  replacingSupplyChain: "Change Supplier",
  cooperationTime: "Cooperate Time",
  商家申样审核标准: 'Seller Sample Request Threshold',
  系统: 'System',
  人工: 'Manual',
  销量门槛: 'Monthly Sold Units',
  粉丝数门槛: 'Follower',
  '修改成功！': 'Successfully modified!',
  修改申样审核标准: 'Modify Sample Request Threshold',
  修改人: 'Modifier',
  件: 'units',
  千: 'K (thousand)',
  系统参考值: 'System reference',
  '请输入必填项！': 'Please fill in the required fields!',
  确认修改: 'Confirm',
  '修改后将影响运营机审条件以及达人选品时申样标准的展示，请仔细检查？': `The modification will affect auto-review conditions and sample request threshold displayed during creator's product selection. Please check carefully!`,
};
