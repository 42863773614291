export default {
  达人表现: "ประสิทธิภาพของ Creator ของ {name}",
  我的达人分析: "การวิเคราะห์ Creator ของฉัน",
  日期区间: "ช่วงวันที่",
  PIC: "PIC",
  "GMV（预估）": "GMV (โดยประมาณ)",
  "TAP佣金收入（预估）": "Tรายได้ค่าคอมTAP (โดยประมาณ)",
  "GMV（完成）": "GMV (จริง)",
  "TAP佣金收入（完成）": "รายได้ค่าคอม TAP (จริง)",
  PIC榜单: "อันดับ PIC",
  "All PIC达人表现": "ประสิทธิภาพของ Creator ทั้งหมดใน PIC",
  PIC达人分析: "การวิเคราะห์ Creator ใน PIC",
  栏目名称: '栏目名称',
  位置: '位置',
  瓷片区: '瓷片区',
  栏目链接: '栏目链接',
  英文: '英文',
  泰文: '泰文',
  越南语: '越南语',
  修改后APP首页将实时生效: '修改后APP首页将实时生效',
  '你还要继续吗？': '你还要继续吗？',
  '修改配置': '修改配置',
};
