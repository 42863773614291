export default {
  达人表现: "{name}'s达人表现",
  我的达人分析: "我的达人分析",
  日期区间: "日期区间",
  PIC: "PIC",
  "GMV（预估）": "GMV（预估）",
  "TAP佣金收入（预估）": "TAP佣金收入（预估）",
  "GMV（完成）": "GMV（完成）",
  "TAP佣金收入（完成）": "TAP佣金收入（完成）",
  PIC榜单: "PIC榜单",
  "All PIC达人表现": "All PIC达人表现",
  PIC达人分析: "PIC达人分析",
  栏目名称: '栏目名称',
  位置: '位置',
  瓷片区: '瓷片区',
  栏目链接: '栏目链接',
  英文: '英文',
  泰文: '泰文',
  越南语: '越南语',
  修改后APP首页将实时生效: '修改后APP首页将实时生效',
  '你还要继续吗？': '你还要继续吗？',
  '修改配置': '修改配置',
};
