export default {
  supplierName: "Tên Nhà Cung Cấp",
  supplierId: "Mã Nhà Cung Cấp",
  numberOfStores: "Số Lượng Cửa Hàng",
  storeName: "Tên Cửa Hàng",
  addTime: "Thời Gian Thêm",
  storeNum: "Mã Cửa Hàng",
  unprocessedSampleRequest: "Yêu Cầu Mẫu Chưa Xử Lý",
  shipLink: "Liên Kết Giao Hàng",
  deleteLinkTips:
    "Sau khi xóa, liên kết trở nên không hợp lệ và thông tin nhà cung cấp không thể khôi phục được. Xác nhận xóa?",
  deleteShopTips:
    "Sau khi xóa, nhà cung cấp sẽ không thể gửi mẫu đến cửa hàng này nữa. Bạn có chắc chắn muốn xóa không?",
  move: "Di Chuyển",
  operationRecords: "Hồ Sơ Hoạt Động",
  cooperativeProductQuantity: "Số Lượng Sản Phẩm Hợp Tác",
  activityProductQuantity: "Số Lượng Sản Phẩm Trong Chiến Dịch",
  canAdd: "Thêm",
  added: "Đã Thêm",
  belongToApp: "Đã Liên Kết với Nhà Cung Cấp Khác",
  addSuccess: "Thêm thành công",
  noticeTips: "Lưu Ý: Chỉ có thể thêm những nhà cung cấp bạn đã hợp tác",
  moveId: "Vui lòng chọn Mã Nhà Cung Cấp mà bạn muốn di chuyển tới",
  supplier: "Nhà Cung Cấp",
  inputShopNameOrNum: "Vui lòng nhập tên cửa hàng hoặc mã cửa hàng để tìm kiếm",
  inputSupplierId: "Tên nhà cung cấp không tồn tại.",
  shelfStatus: "Trạng Thái Nhà Cung Cấp",
  immediateProcessing: "Liên Kết Ngay Bây Giờ",
  lastSampleDate: "Thời Gian Yêu Cầu Mẫu Cuối Cùng",
  sample3day: "Chờ Phê Duyệt (3 ngày qua)",
  merchantContactInformation: "Thông tin liên lạc",
  noBindPer: "x cửa hàng đang chờ liên kết với nhà cung cấp!",
  willBindNew: "Sau khi tạo, xxx sẽ được liên kết với nhà cung cấp này",
  waitBindShop: "Cửa Hàng Chờ Liên Kết",
  pendingMerchantReviewNum: "Số Lượng Đang Chờ Phê Duyệt",
  newSupplyChain: "Nhà Cung Cấp Mới",
  bindSupplyChain: "Liên Kết Nhà Cung Cấp",
  replacingSupplyChain: "Thay Đổi Nhà Cung Cấp",
  cooperationTime: "Thời Gian Hợp Tác",
  商家申样审核标准: 'Tiêu chuẩn xét duyệt mẫu của người bán',
  系统: 'Hệ thống',
  人工: 'Thủ công',
  销量门槛: 'Tiêu chuẩn doanh số',
  粉丝数门槛: 'Tiêu chuẩn số lượng người theo dõi',
  '修改成功！': 'Chỉnh sửa thành công!',
  修改申样审核标准: 'Sửa đổi tiêu chuẩn xin mẫu',
  修改人: 'Người chỉnh sửa',
  件: 'sản phẩm',
  千: 'nghìn',
  系统参考值: 'Giá trị tham khảo của hệ thống',
  '请输入必填项！': 'Vui lòng điền thông tin vào các ô bắt buộc!',
  确认修改: 'Xác nhận chỉnh sửa',
  '修改后将影响运营机审条件以及达人选品时申样标准的展示，请仔细检查？': 'Việc sửa đổi sẽ ảnh hưởng đến điều kiện và tiêu chuẩn duyệt mẫu tự động. Vui lòng kiểm tra cẩn thận!',
};
