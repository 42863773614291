export default {
  supplierName: "供应商名称",
  supplierId: "供应商id",
  numberOfStores: "店铺数量",
  storeName: "店铺名称",
  addTime: "添加时间",
  storeNum: "店铺号",
  unprocessedSampleRequest: "未处理样品申请",
  shipLink: "发货链接",
  deleteLinkTips: "删除后链接失效，并无法恢复该供应商信息，是否确认删除？",
  deleteShopTips: "删除后该供应商无法为该店铺样品发货，是否确认删除",
  move: "移动",
  operationRecords: "操作记录",
  cooperativeProductQuantity: "合作商品数",
  activityProductQuantity: "活动中的商品数",
  canAdd: "可添加",
  added: "已添加",
  belongToApp: "归属其他供应商",
  addSuccess: "添加成功",
  noticeTips: "注意：仅能添加合作过的商家",
  moveId: "请选择要移动至的供应商id",
  supplier: "供应商",
  inputShopNameOrNum: "输入店铺名称或店铺号搜索",
  inputSupplierId: "供应商名称不存在",
  shelfStatus: "商家上架状态",
  pendingMerchantReviewNum: "待商家审核样品数量",
  newSupplyChain: "新建供应链",
  bindSupplyChain: "绑定供应链",
  replacingSupplyChain: "更换供应链",
  cooperationTime: "合作时间",
  // 样品优化
  immediateProcessing: "立即处理",
  lastSampleDate: "最后一次申样日期",
  sample3day: "近3天未处理样品申请",
  merchantContactInformation: "商家联系方式",
  noBindPer: "x 家店铺有样品申请但未绑定供应链，请及时处理！",
  waitBindShop: "店铺待绑定",
  willBindNew: "新建后, xxx将绑定在该新供应链下",
  商家申样审核标准: '商家申样审核标准',
  系统: '系统',
  人工: '人工',
  销量门槛: '销量门槛',
  粉丝数门槛: '粉丝数门槛',
  '修改成功！': '修改成功！',
  修改申样审核标准: '修改申样审核标准',
  修改人: '修改人',
  件: '件',
  千: '千',
  系统参考值: '系统参考值',
  '请输入必填项！': '请输入必填项！',
  确认修改: '确认修改',
  '修改后将影响运营机审条件以及达人选品时申样标准的展示，请仔细检查？': '修改后将影响运营机审条件以及达人选品时申样标准的展示，请仔细检查？',
};
