const bag_icon =
  "https://file-th.ypkclient.com/system/20230915/131aafd7c8904df1ac145cd29adf20c8.png";
const bag_icon_active =
  "https://file-th.ypkclient.com/system/20230915/77f257b87ef645b2979c186e98e2a5c1.png";

const operationRoute: IRouteProps[] = [
  {
    path: "/product-manage",
    name: "商品管理",
    meta: { icon: bag_icon, activeIcon: bag_icon_active },
    routes: [
      {
        path: "/product-manage/tt-products/normal",
        name: "普通活动",
        component: "./tt-products/index"
      },
      {
        path: "/product-manage/tt-products/normal/source/:id",
        name: "素材编辑",
        component: "./tt-products/SourcePage",
        hideInMenu: true,
      },
      {
        path: "/product-manage/tt-products/star",
        name: "明星活动",
        component: "./tt-products/star"
      },
      {
        path: "/product-manage/tt-products/star/source/:id",
        name: "素材编辑",
        component: "./tt-products/SourcePage",
        hideInMenu: true,
      },
      {
        path: "/product-manage/tt-collections",
        name: "商品集",
        component: "./collection"
      }
    ]
  }
];

export default operationRoute;
