export default {
  通知方式: "Phương thức thông báo",
  APP推送: "Đẩy APP",
  标题: "Tiêu đề",
  消息内容: "Nội dung tin nhắn",
  打开跳转至: "Mở để chuyển đến",
  橱窗检测: "Kiểm tra trưng bày",
  数据榜单首页: "Trang chủ bảng xếp hạng dữ liệu",
  AI话题首页: "Trang chủ chủ đề AI",
  AI定制: "Tùy chỉnh AI",
  待发送: "Chờ gửi",
  已发送: "Đã gửi",
  发送失败: "Gửi thất bại",
  推送时间: "Thời gian đẩy",
  接收人: "Người nhận",
  所有用户: "Tất cả người dùng",
  创建人: "Người tạo",
  查看自定义推送: "Xem đẩy tùy chỉnh",
  编辑自定义推送: "Chỉnh sửa đẩy tùy chỉnh",
  创建自定义推送: "Tạo đẩy tùy chỉnh",
  全部用户: "Tất cả người dùng",
  指定用户: "Người dùng được chỉ định",
  定时发送: "Gửi theo lịch",
  即刻发送: "Gửi ngay lập tức",
  内容分类: "Phân loại nội dung",
  通知: "Thông báo",
  推荐: "Đề xuất",
  频次: "Tần suất",
  仅一次: "Chỉ một lần",
  每n天一次: "Mỗi {ngày} ngày một lần",
  通知名称: "Tên thông báo",
  触发节点: "Nút kích hoạt",
  待审核转为待发货: "Chờ xét duyệt chuyển sang chờ giao hàng",
  待发货转为已发货: "Chờ giao hàng chuyển sang đã giao",
  待发货转为待履约: "Chờ giao hàng chuyển sang chờ thực hiện",
  橱窗账号登录状态异常: "Trạng thái đăng nhập tài khoản trưng bày bất thường",
  存在橱窗高佣待替换商品: "Sản phẩm hoa hồng cao cần thay thế trong trưng bày",
  没有橱窗登录过的用户: "Người dùng chưa từng đăng nhập trưng bày",
  没有开启自动替换高佣的用户: "Người dùng chưa kích hoạt nút tự động thay thế",
  未点击过加入推广计划按钮的用户: "Người dùng chưa nhấp vào nút Tham gia kế hoạch quảng bá",
  "榜单推荐-U选爆品": "Đề xuất bảng xếp hạng - U Chọn sản phẩm phổ biến",
  "榜单推荐-达人带货榜": "Đề xuất bảng xếp hạng - Người có ảnh hưởng mang hàng",
  "榜单推荐-U选高佣": "Đề xuất bảng xếp hạng - U Chọn hoa hồng cao",
  "榜单推荐-AI热点主题榜": "Đề xuất bảng xếp hạng - Chủ đề nóng AI",
  "榜单推荐-持续好货榜": "Đề xuất bảng xếp hạng - Hàng hóa tốt liên tục",
  "榜单推荐-达人涨粉榜": "Đề xuất bảng xếp hạng - Tăng người theo dõi người có ảnh hưởng",
  "榜单推荐-日销商品榜": "Đề xuất bảng xếp hạng - Sản phẩm bán hàng ngày",
  "榜单推荐-运营主推tap": "Đề xuất bảng xếp hạng - Hoạt động chính",
  所有用户包括访客: "Tất cả người dùng bao gồm khách",
  申样用户: "Người dùng ứng dụng mẫu",
  橱窗授权用户: "Người dùng được ủy quyền trưng bày",
  已登录过橱窗的用户: "Người dùng đã đăng nhập trưng bày",
  默认跳转: "Chuyển hướng mặc định",
  指定H5页面: "Trang H5 được chỉ định",
  APP内页面: "Trang trong APP",
  单商品: "Sản phẩm đơn lẻ",
  待发货订单列表: "Danh sách đơn hàng chờ giao",
  待履约订单列表: "Danh sách đơn hàng chờ thực hiện",
  橱窗检测账号列表: "Danh sách tài khoản kiểm tra trưng bày",
  高佣可替换商品列表: "Danh sách sản phẩm có thể thay thế hoa hồng cao",
  APP指定商品集落地页: "Trang đích bộ sưu tập sản phẩm được chỉ định trong APP",
  APP数据持续好货榜: "Bảng xếp hạng hàng hóa tốt liên tục của APP",
  编辑内容: "Chỉnh sửa nội dung",
  确认关闭推送消息: "Xác nhận đóng thông báo đẩy",
  确认修改推送内容: "Xác nhận sửa đổi nội dung đẩy",
  "确认后内容将生效，你还要继续吗？":
    "<span style='color: red'>Sau khi xác nhận, nội dung sẽ có hiệu lực,</span> bạn có muốn tiếp tục không?",
  正在编辑推送内容: "Đang chỉnh sửa nội dung đẩy",
  推送标题: "Tiêu đề đẩy",
  请输入至少五个字符: "Vui lòng nhập ít nhất năm ký tự",
  "确认后将不再推送此条消息，你还要继续吗？":
    "<span style='color: red'>Sau khi xác nhận, thông báo này sẽ không còn được đẩy nữa,</span> bạn có muốn tiếp tục không?",
  关闭推送: "Đóng đẩy",
  开启推送: "Bật đẩy",
  "TAP榜单-免费样品": "Bảng xếp hạng uChoice - Mẫu miễn phí",
  "TAP榜单-收藏夹": "Bảng xếp hạng uChoice - Danh sách yêu thích",
  "TAP榜单-添加橱窗": "Bảng xếp hạng uChoice - Thêm trưng bày",
  "TAP榜单- 30天畅销榜单": "Bảng xếp hạng uChoice - Bán chạy nhất trong 30 ngày",
  当天有订单审核被拒且无通过的: "Đơn hàng bị từ chối xét duyệt trong ngày mà không có phê duyệt",
  当天有账号升级变动: "Thay đổi nâng cấp tài khoản trong ngày",
  L0账号触发给样机制: "Tài khoản L0 kích hoạt cơ chế phân phối mẫu",
  新品到货: "Hàng mới về",
  推广记录建议: "Gợi ý ghi chú quảng bá",
  每日6款推荐: "Đề xuất 6 sản phẩm mỗi ngày",
  L0等级用户: "Người dùng cấp độ L0",
  过去30天有推广记录的用户: "Người dùng có ghi chú quảng bá trong 30 ngày qua",
  有授权记录的用户: "Người dùng có ghi chú ủy quyền",
  过去7天有推广记录的用户: "Người dùng có hồ sơ khuyến mãi 7 ngày qua",
  过去7天无推广记录的用户: "Người dùng không có hồ sơ khuyến mãi 7 ngày qua",
  所有已登录用户: "Tất cả người dùng telah dang nhập",
  "推广记录建议--近7天有数据": "Hồ sơ khuyến.maxcdn 7 ngày qua",
  "推广记录建议--近7天无数据": "Hồ sơ khuyến.maxcdn 7 ngày qua",
  "爆单提醒-用户当天销量大于30单": "Nhắc nhỏ đơn hàng - Doanh số ngày hóm nay > 30 đơn",
  "TikTok数据榜单-店铺榜": "Bảng xếp hạng TikTok - Xếp hạng cửa hàng",
  "TikTok数据榜单-潜力爆品榜": "Bảng xếp hạng TikTok - Sản phẩm tiềm năng"
};
