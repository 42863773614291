export default {
  达人表现: "{name}'s Creator Performance",
  我的达人分析: "My Creator Analysis",
  日期区间: "Date Range",
  PIC: "PIC",
  "GMV（预估）": "GMV (Estimated)",
  "TAP佣金收入（预估）": "TAP Commission Income (Estimated)",
  "GMV（完成）": "GMV (Actual)",
  "TAP佣金收入（完成）": "TAP Commission Income (Actual)",
  PIC榜单: "PIC Ranking",
  "All PIC达人表现": "All PIC Creator Performance",
  PIC达人分析: "PIC Creator Analysis",
  栏目名称: '栏目名称',
  位置: '位置',
  瓷片区: '瓷片区',
  栏目链接: '栏目链接',
  英文: '英文',
  泰文: '泰文',
  越南语: '越南语',
  修改后APP首页将实时生效: '修改后APP首页将实时生效',
  '你还要继续吗？': '你还要继续吗？',
  '修改配置': '修改配置',
};
