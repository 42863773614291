export default {
  素材: "ข้อมูลบรีฟ",
  素材编辑: "แก้ไขข้อมูล",
  基础信息: "ข้อมูลพื้นฐาน",
  商品名称: "ชื่อสินค้า",
  商品ID: "รหัสสินค้า",
  商品价格: "ราคาสินค้า",
  参考视频: "วิดีโอตัวอย่าง",
  最多上传6个视频: "อัปโหลดวิดีโอได้สูงสุด 6 รายการ",
  TikTok视频链接: "ลิงก์วิดีโอ TikTok",
  新增链接: "เพิ่มลิงก์",
  卖点参考: "ตัวอย่างจุดขาย",
  最多可填写5个卖点: "เพิ่มได้สูงสุด 5 จุดขาย",
  新增卖点: "เพิ่มใหม่",
  brief参考: "ตัวอย่างบรีฟ",
  请上传brief脚本链接: "กรุณาอัปโหลดลิงก์บรีฟสคริปต์",
  brief链接: "ลิงก์บรีฟ",
  图片素材: "ข้อมูลรูปภาพ",
  "最多上传9张图片，每张图片不大于5M": "อัปโหลดได้สูงสุด 9 รูป (แต่ละรูปไม่เกิน 5M)",
  上传多张图片: "อัปโหลดหลายภาพ",
  视频素材: "ข้อมูลวิดีโอ",
  "最多上传9个视频，每个视频不大于50M": "อัปโหลดได้สูงสุด 9 คลิป (แต่ละคลิปไม่เกิน 50M)",
  上传多个视频: "อัปโหลดหลายวิดีโอ",
  保存: "บันทึก",
  点击或将文件拖拽到这里上传: "คลิกหรือลากไฟล์เพื่ออัปโหลด",
  "支持扩展名：.mp4、.mov、.m4v": "รูปแบบที่รองรับ: .mp4, .mov, .m4v",
  继续保存: "บันทึกต่อ",
  "将在APP端展示您上传的资料，你还要继续吗？":
    '<sapn style="color: red;">ข้อมูลจะแสดงบนแอป</sapn> คุณต้องการดำเนินการต่อหรือไม่?',
  放弃素材编辑: "ยกเลิกการแก้ไขข้อมูล",
  "当前编辑将全部失效，你还要继续吗？": "การแก้ไขทั้งหมดจะหายไป คุณต้องการดำเนินการต่อหรือไม่?",
  取消: "ยกเลิก",
  继续: "ดำเนินการต่อ",
  "保存成功！": "บันทึกสำเร็จ!",
  卖点x: "จุดขาย {index}",
  卖点x简介: "คำอธิบายจุดขาย {index}",
  上传: "อัปโหลด",
  'You can only upload JPG/JPEG/PNG/GIF file!': 'คุณสามารถอัปโหลดไฟล์ JPG/JPEG/PNG/GIF ได้เท่านั้น!',
  'Image must smaller than 5MB!': 'รูปภาพต้องมีขนาดไม่เกิน 5MB!',
  "You can only upload mp4/mov/m4v file!": "คุณสามารถอัปโหลดไฟล์ mp4/mov/m4v ได้เท่านั้น!",
  "Video must smaller than 50MB!": "วิดีโอต้องมีขนาดไม่เกิน 50MB!",
  "Upload failed": "การอัปโหลดล้มเหลว",
};
