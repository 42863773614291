export default {
  supplierName: "ชื่อซับพลายเออร์",
  supplierId: "รหัสซับพลายเออร์",
  numberOfStores: "จำนวนร้านค้า",
  storeName: "ชื่อร้านค้า",
  addTime: "เวลาที่เพิ่มเข้าไป",
  storeNum: "รหัสร้านค้า",
  unprocessedSampleRequest: "คำขอสินค้าตัวอย่างที่ยังไม่ได้รับการดำเนินการ",
  shipLink: "ลิงก์จัดส่งสินค้า",
  deleteLinkTips:
    "หลังจากลบแล้ว ลิงก์นี้จะไม่สามารถใช้งานและข้อมูซัพพลายเออร์จะหายไป คุณแน่ใจหรือไม่ว่าต้องการลบ",
  deleteShopTips:
    "หลังจากลบแล้ว ซับพลายเออร์จะไม่สามารถจัดส่งสินค้าไปยังร้านค้าได้ คุณแน่ใจที่จะลบแล้ว หรือไม่",
  move: "ย้าย",
  operationRecords: "ประวัติการดำเนินการ",
  cooperativeProductQuantity: "จำนวนสินค้าที่เข้าร่วม",
  activityProductQuantity: "จำนวนสินค้าในกิจกรรม",
  canAdd: "เพิ่ม",
  added: "เพิ่มแล้ว",
  belongToApp: "เป็นของซับพลายเออร์รายอื่น",
  addSuccess: "เพิ่มสำเร็จ",
  noticeTips: "หมายเหตุ: สามารถเพิ่มได้เพียงซับพลายเออร์ที่เข้าร่วมเท่านั้น",
  moveId: "กรุณาเลือกรหัสซับพลายเออร์ที่จะย้ายไป",
  supplier: "ซับพลายเออร์",
  inputShopNameOrNum: "กรุณาใส่ชื่อร้านค้า หรือรหัสร้านค้าที่จะทำการค้นหา",
  inputSupplierId: "ไม่มีซัพพลายเออร์นี้",
  shelfStatus: "สถานะร้านค้า",
  immediateProcessing: "เชื่อมต่อเดี๋ยวนี้",
  lastSampleDate: "เวลาการขอตัวอย่างครั้งล่าสุด",
  sample3day: "รอการอนุมัติ (ภายใน 3 วัน)",
  merchantContactInformation: "เวลาการขอตัวอย่างครั้งล่าสุด",
  noBindPer: "ร้าน x มีการขอสินค้าตัวอย่างแต่ยังไม่ได้เชื่อมกับซัพพลายเออร์,กรุณาดำเนินการทันที!",
  willBindNew: "หลังจากสร้าง, xxx จะเชื่อมต่อกับซัพพลายเออร์นี้",
  waitBindShop: "ร้านค้ารอการผูกมัด",
  pendingMerchantReviewNum: "รอการอนุมัติจากร้านค้า",
  newSupplyChain: "ซัพพลายเออร์ใหม่",
  bindSupplyChain: "เชื่อมต่อกับซัพพลายเออร์",
  replacingSupplyChain: "เปลี่ยนซัพพลายเออร์",
  cooperationTime: "เวลา",
  商家申样审核标准: 'เกณฑ์การพิจารณาสำหรับการขอตัวอย่างของร้านค้า',
  系统: 'ระบบ',
  人工: 'แมน่วล',
  销量门槛: 'เกณฑ์ยอดขาย',
  粉丝数门槛: 'เกณฑ์ผู้ติดตาม',
  '修改成功！': 'แก้ไขสำเร็จ!',
  修改申样审核标准: 'แก้ไขเกณฑ์การขอตัวอย่าง',
  修改人: 'ผู้แก้ไข',
  件: 'ชิ้น',
  千: 'พัน',
  系统参考值: 'ค่าอ้างอิงของระบบ',
  '请输入必填项！': 'กรุณากรอกข้อมูลในช่องที่จำเป็น!',
  确认修改: 'ยืนยันการแก้ไข',
  '修改后将影响运营机审条件以及达人选品时申样标准的展示，请仔细检查？': 'การแก้ไขจะมีผลต่อการตรวจสอบอัตโนมัติและการแสดงผลในหน้าการขอสินค้าตัวอย่าง โปรดตรวจสอบโดยละเอียด!',
};
