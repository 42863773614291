export default {
  通知方式: "วิธีการแจ้งเตือน",
  APP推送: "การแจ้งเตือนผ่านแอป",
  标题: "หัวข้อ",
  消息内容: "เนื้อหาข้อความ",
  打开跳转至: "เปิดเพื่อข้ามไปยัง",
  橱窗检测: "การตรวจสอบหน้าร้าน",
  数据榜单首页: "หน้าแรกของตารางข้อมูล",
  AI话题首页: "หน้าแรกของหัวข้อ AI",
  AI定制: "การปรับแต่ง AI",
  待发送: "รอการส่ง",
  已发送: "ส่งแล้ว",
  发送失败: "การส่งล้มเหลว",
  推送时间: "เวลาการแจ้งเตือน",
  接收人: "ผู้รับ",
  所有用户: "ผู้ใช้ทั้งหมด",
  创建人: "ผู้สร้าง",
  查看自定义推送: "ดูการแจ้งเตือนที่กำหนดเอง",
  编辑自定义推送: "แก้ไขการแจ้งเตือนที่กำหนดเอง",
  创建自定义推送: "สร้างการแจ้งเตือนที่กำหนดเอง",
  全部用户: "ผู้ใช้ทั้งหมด",
  指定用户: "ผู้ใช้ที่ระบุ",
  定时发送: "การส่งตามเวลาที่กำหนด",
  即刻发送: "การส่งทันที",
  内容分类: "ประเภทเนื้อหา",
  通知: "การแจ้งเตือน",
  推荐: "คำแนะนำ",
  频次: "ความถี่",
  仅一次: "ครั้งเดียวเท่านั้น",
  每n天一次: "ทุก {day} วันครั้ง",
  通知名称: "ชื่อการแจ้งเตือน",
  触发节点: "จุดที่กระตุ้น",
  待审核转为待发货: "รอตรวจสอบเป็นรอการจัดส่ง",
  待发货转为已发货: "รอการจัดส่งเป็นจัดส่งแล้ว",
  待发货转为待履约: "รอการจัดส่งเป็นรอการปฏิบัติ",
  橱窗账号登录状态异常: "สถานะการเข้าสู่ระบบบัญชีหน้าร้านผิดปกติ",
  存在橱窗高佣待替换商品: "สินค้าคอมมิชชั่นสูงที่รอการเปลี่ยนในหน้าร้าน",
  没有橱窗登录过的用户: "ผู้ใช้ที่ไม่เคยเข้าสู่ระบบโชว์เคส",
  没有开启自动替换高佣的用户: "ผู้ใช้ที่ไม่เคยเปิดใช้เครื่องมือเพิ่มค่าคอมมาก่อน",
  未点击过加入推广计划按钮的用户: "ผู้ใช้ที่ไม่เคยคลิกเพิ่มโปรโมชันมาก่อน",
  "榜单推荐-U选爆品": "คำแนะนำตาราง-U เลือกสินค้ายอดนิยม",
  "榜单推荐-达人带货榜": "คำแนะนำตาราง-อินฟลูเอนเซอร์นำสินค้า",
  "榜单推荐-U选高佣": "คำแนะนำตาราง-U เลือกคอมมิชชั่นสูง",
  "榜单推荐-AI热点主题榜": "คำแนะนำตาราง-หัวข้อ AI ยอดนิยม",
  "榜单推荐-持续好货榜": "คำแนะนำตาราง-สินค้าดีต่อเนื่อง",
  "榜单推荐-达人涨粉榜": "คำแนะนำตาราง-การเติบโตของผู้ติดตามอินฟลูเอนเซอร์",
  "榜单推荐-日销商品榜": "คำแนะนำตาราง-สินค้าขายรายวัน",
  "榜单推荐-运营主推tap": "คำแนะนำตาราง-แท็บหลักการดำเนินงาน",
  所有用户包括访客: "ผู้ใช้ทั้งหมดรวมถึงผู้เข้าชม",
  申样用户: "ผู้ใช้ยื่นขอตัวอย่าง",
  橱窗授权用户: "ผู้ใช้ที่ได้รับอนุญาตหน้าร้าน",
  已登录过橱窗的用户: "ผู้ใช้ที่เคยเข้าสู่ระบบโชว์เคส",
  默认跳转: "การข้ามโดยปริยาย",
  指定H5页面: "หน้า H5 ที่ระบุ",
  APP内页面: "หน้าในแอป",
  单商品: "สินค้าชิ้นเดียว",
  待发货订单列表: "รายการคำสั่งซื้อที่รอการจัดส่ง",
  待履约订单列表: "รายการคำสั่งซื้อที่รอการปฏิบัติ",
  橱窗检测账号列表: "รายการบัญชีการตรวจสอบหน้าร้าน",
  高佣可替换商品列表: "รายการสินค้าที่สามารถเปลี่ยนคอมมิชชั่นสูง",
  APP指定商品集落地页: "หน้า Landing Page สินค้าที่ระบุในแอป",
  APP数据持续好货榜: "ตารางข้อมูลสินค้าดีต่อเนื่องในแอป",
  编辑内容: "แก้ไขเนื้อหา",
  确认关闭推送消息: "ยืนยันปิดการแจ้งเตือน",
  确认修改推送内容: "ยืนยันการแก้ไขเนื้อหาการแจ้งเตือน",
  "确认后内容将生效，你还要继续吗？":
    "<span style='color: red'>หลังจากยืนยัน เนื้อหาจะมีผล,</span> คุณต้องการดำเนินการต่อหรือไม่?",
  正在编辑推送内容: "กำลังแก้ไขเนื้อหาการแจ้งเตือน",
  推送标题: "หัวข้อการแจ้งเตือน",
  请输入至少五个字符: "กรุณาใส่ตัวอักษรอย่างน้อยห้าตัว",
  "确认后将不再推送此条消息，你还要继续吗？":
    "<span style='color: red'>หลังจากยืนยัน ข้อความนี้จะไม่ถูกส่งอีกต่อไป,</span> คุณต้องการดำเนินการต่อหรือไม่?",
  关闭推送: "ปิดการแจ้งเตือน",
  开启推送: "เปิดการแจ้งเตือน",
  "TAP榜单-免费样品": "จัดอันดับโดย Uchoice - อันดับสินค้าตัวอย่าง",
  "TAP榜单-收藏夹": "จัดอันดับโดย Uchoice - อันดับรายการโปรด",
  "TAP榜单-添加橱窗": "จัดอันดับโดย Uchoice - อันดับ Tiktok โชว์เคส",
  "TAP榜单- 30天畅销榜单": "จัดอันดับโดย Uchoice - อันดับสินค้าขายดี",
  当天有订单审核被拒且无通过的: "คำสั่งซื้อที่ถูกปฏิเสธการตรวจสอบและไม่มีการอนุมัติในวันเดียวกัน",
  当天有账号升级变动: "การเปลี่ยนแปลงการอัปเกรดบัญชีในวันเดียวกัน",
  L0账号触发给样机制: "บัญชี L0 เรียกใช้กลไกการแจกจ่ายตัวอย่าง",
  新品到货: "สินค้ามาใหม่",
  推广记录建议: "ข้อเสนอแนะการบันทึกการโปรโมต",
  每日6款推荐: "แนะนำสินค้า 6 รายการทุกวัน",
  L0等级用户: "ผู้ใช้ระดับ L0",
  过去30天有推广记录的用户: "ผู้ใช้ที่มีประวัติการโปรโมตในช่วง 30 วันที่ผ่านมา",
  有授权记录的用户: "ผู้ใช้ที่มีประวัติการอนุญาต",
  过去7天有推广记录的用户: "ผู้ใช้ที่มีประวัติการโปรโมตใน 7 วัน",
  过去7天无推广记录的用户: "ผู้ใช้ที่ไม่มีประวัติการโปรโมตใน 7 วัน",
  所有已登录用户: "ผู้ใช้ที่ลงทะเบียนแล้ว",
  "推广记录建议--近7天有数据": "ประวัติการโปรโมต - มีข้อมูลใน 7 วัน",
  "推广记录建议--近7天无数据": "ประวัติการโปรโมต - ไม่มีข้อมูลใน 7 วัน",
  "爆单提醒-用户当天销量大于30单": "แจ้งเตือน-ผู้ใช้ที่ยอดขายเกิน 30 รายการต่อวัน ",
  "TikTok数据榜单-店铺榜": "จัดอันดับโดย Tiktok - อันดับร้านค้า",
  "TikTok数据榜单-潜力爆品榜": "จัดอันดับโดย Tiktok - อันดับสินค้ามาแรง"
};
