import { getUliveAdminBaseUrl } from "@/utils/getBaseUrl";
import http from "./instance";
import localforage from "localforage";

export interface SalesThreshold {
  maxAmount: number;
  minAmount?: number;
  quantity: number;
  tier: number;
}
export interface autoReview {
  fulfillmentRate?: number;
  sampleAmount?: number;
}
export interface EditSalesThresholdParams {
  limitType: number;
  total: number;
  thresholdList: SalesThreshold[];
  sampleAudit: autoReview;
}
export interface GetSalesThresholdDatas {
  limitType: number;
  total: number;
  salesInfoVos: SalesThreshold[];
  sampleAudit: autoReview;
}
export interface OrderNoParams {
  orderNo: number;
}

export interface CheckSalesRangByOrderNoResult {
  isChangeSalesRang: boolean;
  tier: number;
  minAmount: number;
  maxAmount: number;
  salesDataVoucher: string;
}

export interface UpdateSalesRangParams {
  maxAmount: number;
  minAmount: number;
  orderNo: number;
  tier: number;
}

export default {
  // 获取订单列表
  getOrderList: (params: any): any => {
    return http.get<any>("/api-uchoice/order/getOrderList", params);
  },
  //  获取订单相关数量
  getOrderStats: (params: any): any => {
    return http.get<any>("/api-uchoice/order/getOrderStats", params);
  },
  //获取物流信息
  getExpressDeliveryInfo: (params: any): any => {
    return http.get<any>("/api-uchoice/order/getExpressDeliveryInfo", params);
  },
  //审核订单
  orderReview: (data: any, lang: any): any => {
    return http.postJSON<any>("/api-uchoice/order/orderReview", data);
  },
  // 发货物流
  ttLogistics: (): any => {
    return http.post<any>("/api-uchoice/statistics/ttLogistics");
  },
  // 发货操作
  shippingOperation: (data: any, lang: any): any => {
    return http.postJSON<any>("/api-uchoice/order/shippingOperation", data);
  },
  // 增补发货操作
  supplementShippingOperation: (data: any, lang: any): any => {
    return http.postJSON<any>("/api-uchoice/order/supplementShippingOperation", data);
  },
  //拒绝操作
  getRejectionReasonList: (): any => {
    return http.get<any>("/api-uchoice/order/getRejectionReasonList");
  },
  // 获取样品订单详情
  sampleOrderDetail: (params: any) => {
    return http.get<any>("/api-uchoice/order/getOrderDetailByOrderNo", params);
  },
  //  表格记录
  getExportingRecords: (params: any) => {
    return http.get<any>("/api-base/export/getExportingRecords", params);
  },
  //导出

  exportOrders: (params: any) => {
    return http.get<any>("/api-uchoice/order/exportOrders", params);
  },
  //新增订单
  dddSampleOrder: (data: any): any => {
    return http.postJSON<any>("/api-uchoice/order/addSampleOrder", data);
  },
  //OPS-获取商品下sku
  getSkuInfoByProductId: (params: any) => {
    return http.get<any>("/api-uchoice/tt/item/getSkuInfoByProductId", params);
  },
  //完成订单
  completeOrder: (data: any): any => {
    return http.postJSON<any>("/api-uchoice/order/completeOrder", data);
  },
  //查询用户样品申请记录
  getSampleOrderRecord: (params: any, language: any): any => {
    return http.get<any>("/api-uchoice/order/getSampleOrderRecord", params);
  },
  // 获取样品信息
  getSampleOrderInfo: (params: any): any => {
    return http.get<any>("/api-uchoice/order/getSampleOrderInfo", params);
  },
  // 修改样品订单
  updateSampleOrder: (params: any): any => {
    return http.postJSON<any>("/api-uchoice/order/updateSampleOrder", params);
  },
  //查询用户收货地址
  getSampleOrderAddress: (params: any, language: any): any => {
    return http.get<any>("/api-uchoice/order/getSampleOrderAddress", params);
  },
  //修改订单数量
  changeOrderItemQuantity: (data: any): any => {
    return http.postJSON<any>("/api-uchoice/order/changeOrderItemQuantity", data);
  },
  //上传视频ID
  uploadVideoId: (data: any): any => {
    return http.postJSON<any>("/api-uchoice/order/uploadVideoId", data);
  },
  //履约审核
  performanceReview: (data: any): any => {
    return http.postJSON<any>("/api-uchoice/order/performanceReview", data);
  },
  // 编辑样品申请门槛
  editThreshold: (params: EditSalesThresholdParams): Promise<IResponseProps<any>> => {
    return http.postJSON<any>("/api-uchoice/item/subsidy/editThreshold", params);
  },
  // 获取销售额层级
  getSalesInfo: (): Promise<IResponseProps<EditSalesThresholdParams[]>> => {
    return http.get<any>("/api-uchoice/item/subsidy/getSalesInfo");
  },
  //获取层级
  getSalesInfoWeb: (): Promise<IResponseProps<GetSalesThresholdDatas>> => {
    return http.get<any>("/api-uchoice/item/subsidy/getSalesInfoWeb");
  },
  // 判断需不需要修改销售范围
  checkSalesRangByOrderNo: (
    params: OrderNoParams
  ): Promise<IResponseProps<CheckSalesRangByOrderNoResult>> => {
    return http.get<any>("/api-uchoice/order/checkSalesRangByOrderNo", params);
  },
  // 更新销量范围
  updateSalesRang: (params: UpdateSalesRangParams): Promise<IResponseProps<any>> => {
    return http.postJSON<any>("/api-uchoice/order/updateSalesRang", params);
  },
  //模糊TT用户名
  getDisplayNames: (params: any): any => {
    return http.get<any>("/api-uchoice/uChoice/member/getDisplayNames", params);
  },
  //获取全部省市区
  getRegionInfoVo: async (): Promise<any> => {
    const cacheTimeKey = "region:getRegionInfoVo:" + getUliveAdminBaseUrl() + ":time";
    const cacheVersionKey = "region:getRegionInfoVo:" + getUliveAdminBaseUrl() + ":version";
    const cacheKey = "region:getRegionInfoVo:" + getUliveAdminBaseUrl();
    let cacheData: any = await localforage.getItem(cacheKey);
    // 清理无用数据，一个月后可以移除清理代码
    localforage
      .removeItem(cacheTimeKey)
      .then(function () {})
      .catch(function (err) {
        console.log(err);
      });
    const cacheVersionData: number | null = await localforage.getItem(cacheVersionKey);

    // 获取当前最新版本号
    const versionData = await http.get<any>("/api-base/region/regionVersion");
    if (
      versionData !== null &&
      versionData.code === 200 &&
      versionData.result !== null &&
      versionData.result > 0
    ) {
      // 获取版本号成功，进行比对
      if (cacheVersionData === null || cacheVersionData !== versionData.result) {
        // 版本号不一致，需要重新获取数据
        cacheData = null;
      }
    }

    if (cacheData === null) {
      cacheData = await http.get<any>("/api-base/region/getRegionInfoVo");
      if (!(cacheData.code === 200 && cacheData.result.length > 0)) {
        cacheData = null;
      }
      if (cacheData !== null) {
        await localforage.setItem(cacheKey, cacheData);
        // 更新版本号
        if (
          versionData !== null &&
          versionData.code === 200 &&
          versionData.result !== null &&
          versionData.result > 0
        ) {
          await localforage.setItem(cacheVersionKey, versionData.result);
        }
      }
    }
    return cacheData;
  },
  importProduct: (data: any, itemSetId: string): Promise<IResponseProps<any>> => {
    return http.upload<any>(`/api-uchoice/itemSet/importProduct?itemSetId=${itemSetId}`, data);
  },
  requestExportingRecords: (params: any): Promise<IResponseProps<any>> => {
    return http.get<any>("/api-base/export/getExportingRecords", params);
  },
  // 获取达人近30天最高gmv
  getGmvRecord: (params: any): Promise<IResponseProps<any>> => {
    return http.get<any>("/api-uchoice/tt/member/gmv/getGmvRecord", params);
  }
};
